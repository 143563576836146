<template>
    <section class="TickerTapeSlice">
        <TickerTape
            :words="transformedWords"
            :to="props.slice.primary.internal_link.url"
            :href="props.slice.primary.external_link.url"
            :id="props.slice.id.replace('$', '-')"
        />
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.TickerTapeBlockSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const transformedWords = computed((): Array<{ word: string }> => {
    return props.slice.items.reduce(
        (previousItem, item) => {
            if (!item.text) return previousItem

            previousItem.push({
                word: item.text,
            })

            return previousItem
        },
        [] as Array<{ word: string }>,
    )
})
</script>

<style lang="scss">
.TickerTapeSlice {
    grid-column: 1 / span 24;
    padding-top: 8rem;

    @include medium-up {
        padding-top: 11.25rem;
    }
}
</style>
