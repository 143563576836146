<template>
    <CustomLink
        :to="to"
        :href="href"
        class="TickerTape"
        :cursor-type="to || href ? 'go' : 'link-hover'"
    >
        <div class="TickerTape__set">
            <template
                v-for="(item, index) in words"
                :key="`word-set-${index}-` + index"
            >
                <span class="TickerTape__word">{{ item.word }}</span>
                <span class="TickerTape__separator"></span>
            </template>
        </div>
        <div class="TickerTape__set">
            <template
                v-for="(item, index) in words"
                :key="`word-set-${index}-` + index"
            >
                <span class="TickerTape__word">{{ item.word }}</span>
                <span class="TickerTape__separator"></span>
            </template>
        </div>
        <div class="TickerTape__set">
            <template
                v-for="(item, index) in words"
                :key="`word-set-${index}-` + index"
            >
                <span class="TickerTape__word">{{ item.word }}</span>
                <span class="TickerTape__separator"></span>
            </template>
        </div>
    </CustomLink>
</template>

<script>
export default defineNuxtComponent({
    props: {
        words: {
            type: Array,
            required: true,
        },
        to: String,
        href: String,
    },
})
</script>

<style lang="scss" scoped>
.TickerTape {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-decoration: none;
}

.TickerTape__set {
    display: flex;
    align-items: center;
    animation: 20s linear infinite normal autoflow-scroll;
    mix-blend-mode: difference;
}

.TickerTape__word {
    @include heading-1;

    color: var(--white);

    display: inline-block;
    white-space: nowrap;
}

.TickerTape__separator {
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--page-theme-base-colour, var(--lime-green));
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    transform: rotate(45deg);

    @include large-up {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

@keyframes autoflow-scroll {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(calc(-100%));
    }
}
</style>
